<template>
  <div class="website__domain">
    <v-row no-gutters>
      <!-- <v-col cols="12" class="d-flex align-center page-header">
        <h2 class="page-title">Domains</h2>
        <v-btn class="ml-auto" color="primary">Buy new domain</v-btn>
      </v-col> -->
      <v-col cols="12">
        <router-link :to="{ name: 'website.domain.connect' }" class="text-decoration-none">
          + Connect Existing domain
        </router-link>
      </v-col>
      <v-col v-if="isLoading" cols="12" class="mt-3">
        <SkeletonLoaders type="card-heading, list-item-three-line, list-item-three-line" />
      </v-col>
      <v-col v-else cols="12" class="elevation-form mt-5 px-5 py-5">
        <Notification v-if="errors.length > 0" :messages="errors" type="error" />
        <div class="d-flex align-center">
          <h3>Primary domain</h3>
          <!-- <v-btn @click="primaryModal = true" class="ml-auto text-decoration-none btn-link">
            Change primary domain?
          </v-btn> -->
        </div>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  Host
                </th>
                <th class="text-left">
                  Status
                </th>
                <th class="text-left">
                  Date Added
                </th>
                <!-- <th v-if="enableRef" class="text-left">
                  Checkout Domain
                </th> -->
                <th class="text-left">Delete</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in domains" :key="item.host">
                <td>{{ item.host }}</td>
                <td>
                  <span :class="[item.connectStatus ? 'connected' : 'disconnected']">
                    {{ item.connectStatus ? 'Connected' : 'Disconnected' }}
                  </span>
                </td>
                <td>{{ datetime.format(item.created_at, 'DD-MM-YYYY hh:mm:ss') }}</td>
                <!-- <td v-if="enableRef">
                  <input
                    v-if="!(item.domainType === 'SystemDefault')"
                    type="checkbox"
                    :id="item.id"
                    :value="item.id"
                    v-model="checkedCheckouts"
                    @change="updateCheckoutDomain(item.id)"
                  />
                </td> -->
                <td>
                  <v-btn @click="removeDomain(item)" class="btn-link"><v-icon small>fas fa-trash</v-icon></v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <div class="mt-3">
          Traffic from all your domains redirects to this primary domain.
          <!-- <v-btn @click="disableRedirection = true" class="text-decoration-none btn-link">
            Disable redirection
          </v-btn> -->
        </div>
      </v-col>
      <v-col cols="12" class="elevation-form mt-5 px-5 py-5">
        <div style="width: 624.00px;margin: auto;">
          <p>
            Để sử dụng tên miền của riêng bạn, bạn cần thêm 2 bản ghi trong phần cài đặt DNS như sau:
          </p>
          <p>
            <b>Bản ghi A (A record)</b> trỏ đến <b>18.217.43.33</b>
            <br />
            <b>Bản ghi CNAME (CNAME record)</b> trỏ đến <b>connect.lattehub.com.</b>
          </p>
          <img
            alt=""
            src="/admin/img/image1.png"
            style="width: 624.00px; height: 89.27px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
            title=""
          />
          <p>+ Trên cloudflare, bạn vào domain-> DNS</p>
          <p>+ Trên namecheap, bạn vào domains -> Advance DNS</p>
          <p>
            + Lưu ý: bạn cần tắt proxy (chuyển hình đám mây màu cam thành màu xám hoặc DNS Only) cho cả bản ghi A và
            CNAME
          </p>
          <img
            alt=""
            src="/admin/img/image3.png"
            style="width: 624.00px; height: 161.93px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
            title=""
          />
          <img
            alt=""
            src="/admin/img/image2.png"
            style="width: 624.00px; height: 77.73px; margin-left: 0.00px; margin-top: 0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
            title=""
          />
        </div>
      </v-col>
    </v-row>

    <!-- Modal disable redirection -->
    <modal model="disableRedirection" title="Disable Redirection">
      <template slot="content">
        <div>
          <p>
            Are you sure you want to disable traffic redirection to your primary domain? This can affect how people find
            your store.
          </p>
        </div>
      </template>
    </modal>

    <!-- Modal change primary domain -->
    <modal title="Change primary domain?" model="primaryModal">
      <template slot="content">
        <div>
          <p>
            Are you sure you want to change your primary domain? This will be what your customers and search engines
            see.
          </p>
        </div>
      </template>
    </modal>
  </div>
</template>
<script>
import { domainService } from '@/apis/domain.s';
import datetime from '@/helpers/datetime';

export default {
  data() {
    return {
      primaryModal: false,
      disableRedirection: false,
      isLoading: false,
      domains: [],
      errors: [],
      datetime,
      checkedCheckouts: [],
      enableRef: false,
    };
  },
  created() {
    this.loadData();
    this.checkEnableRef();
  },
  methods: {
    async loadData() {
      this.isLoading = true;
      try {
        let domainData = await domainService.list();
        if (domainData.data) {
          this.domains = domainData.data;
          domainData.data.forEach(item => {
            if (item.isCheckOutDomain === true) {
              this.checkedCheckouts.push(item.id);
            }
          });
        } else {
          localStorage.clear();
        }
        this.isLoading = false;
        console.log(domainData);
      } catch (error) {
        console.log(error, ' error');

        this.isLoading = false;
        this.errors = error;
      }
    },
    async removeDomain(item) {
      try {
        await domainService.disConnect({ host: item.host });
        // let index = this.domains.findIndex(host => host.host == host);
        // if (item.domainType === 'Primary' || item.domainType === 'PrimaryWWW') {
        //   window.location.href = 'https://' + this.$store.state.auth.accountInfo.rDomain;
        // } else {
        //   this.loadData();
        // }
        this.loadData();
      } catch (error) {
        this.errors = error;
      }
    },
    async updateCheckoutDomain(id) {
      let isCheckOut;
      if (this.checkedCheckouts.includes(id)) {
        isCheckOut = true;
      } else {
        isCheckOut = false;
      }
      try {
        await domainService.addCheckoutDomain({ domainId: id, isCheckOutDomain: isCheckOut });
      } catch (error) {
        this.error = error;
      }
    },
    async checkEnableRef() {
      try {
        const checkEnableRef = await domainService.checkEnableRef();
        if (checkEnableRef) {
          this.enableRef = checkEnableRef.data[0].enableRef;
        }
      } catch (error) {
        this.errors = error;
      }
    },
  },
};
</script>
<style lang="scss">
.website__domain {
  .connected {
    background-color: $main-color;
    padding: 5px 10px;
    border-radius: 10px;
    color: #fff;
  }
  .disconnected {
    background-color: #ddd;
    padding: 5px 10px;
    border-radius: 10px;
  }
}
</style>
